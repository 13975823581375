import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Technologies = () => {
    return (
        <StaticQuery
            query={graphql`
                {
                    file(name: {eq: "about"}) {
                        childMarkdownRemark {
                            frontmatter {
                                technologies {
                                    technology {
                                        title
                                        logo
                                    }
                                }
                                techList {
                                    techListItem
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section className="section">
                    <h1 className="subtitle is-3" align="center">Technologies</h1>
                    
                    <div className="container">
                        <div className="columns is-centered">
                            {data.file.childMarkdownRemark.frontmatter.technologies.map(({ technology }, i ) => (
                                <div className="column is-one-fifth" key={i}>
                                    <div className="box">
                                        <figure className="image is-square" align="center">
                                            <img className="has-ratio" alt="" src={technology.logo} />
                                        </figure>
                                        <h1 className="subtitle is-4" align="center">{technology.title}</h1>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <p className="subtitle is-4" align="center">
                            {data.file.childMarkdownRemark.frontmatter.techList.map(({ techListItem }, i, { length }) => (
                                <>
                                {techListItem + (i + 1 === length ? "" : " • ")}
                                </>
                            ))}
                        </p>
                    </div>
                </section>
            )}
        />
    )
}

export default Technologies