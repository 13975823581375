import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Motivations = () => {
    return (
        <StaticQuery 
            query={graphql`
                {
                    file(name: {eq: "about"}) {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            `}
            render={data => (
                <section className="section has-background-light">
                    <h1 className="subtitle is-3" align="center">Motivations</h1>

                    <div className="container">
                        <div className="columns is-centered">
                            <div 
                                className="column is-half" 
                                dangerouslySetInnerHTML={{ __html: data.file.childMarkdownRemark.html }}
                            />
                        </div>
                    </div>
                </section>
            )}
        />
    )
}

export default Motivations