import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Assets = () => {
    return (
        <StaticQuery 
            query={graphql`
                {
                    file(name: {eq: "about"}) {
                        childMarkdownRemark {
                            frontmatter {
                                assets {
                                    asset {
                                        title
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section className="section">
                    <h1 className="subtitle is-3" align="center">Assets</h1>
                    
                    <div className="container">
                        <div className="columns">
                            {data.file.childMarkdownRemark.frontmatter.assets.map(({ asset }, i) => (
                                <div className="column" key={i}>
                                    <div className="box asset">
                                        <h1 className="subtitle is-4">{asset.title}</h1>
                                        <p>{asset.text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        />
    )
}

export default Assets