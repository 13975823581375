import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Assets from '../components/assets'
import Technologies from '../components/technologies'
import Motivations from '../components/motivations'

const AboutPage = () => {
    return (
        <Layout>
            <Seo title="About" />
            
            <Assets />

            <Motivations />
            
            <Technologies />
        </Layout>
    )
}

export default AboutPage